<template>
    <div :class="['MyTimeline', {[`MyTimeline--${size}`]: size}]">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "MyTimeline",
        props: {
            size: String,
        }
    }
</script>

<style lang="scss" scoped>
    .MyTimeline {
        //box-sizing: border-box;
        //margin: 0;
        //padding: 0;
        //color: rgba(0, 0, 0, .65);
        //font-size: 14px;
        //font-variant: tabular-nums;
        //line-height: 1.5715;
        //list-style: none;
        //font-feature-settings: "tnum";
        //width: 100%;
        .MyTimelineItem {
            &:last-child {
                ::v-deep .MyTimelineItem__tail {
                    display: none;
                }
            }
        }
    }
    .MyTimeline--small {

    }
</style>
